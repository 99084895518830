@tailwind base;
@tailwind components;

@tailwind utilities;

.form-label {
  @apply text-12 font-semibold text-black-55 inline-block leading-4;
}

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: currentColor; /* 2 */
}
html {
  font-size: 16px;
}

body {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Ubuntu,
    'Helvetica Neue',
    sans-serif;

  color: #999 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

a,
a:hover {
  text-decoration: none;
  color: #1890ff;
}

.app-container {
  @apply max-w-content mx-auto dashboard;
}

.ant-btn-danger {
  box-shadow: none !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.search input[type='text'] {
  /* background-image: url("/public/home/images/svg/search.svg"); */
  /* background-repeat:no-repeat; */
  font-size: 14px;
  /* text-transform: uppercase; */
  padding-left: 10px;
  padding-right: 10px;
  height: 36px;
  width: 360px;
  /* overflow: hidden; */
  border-radius: 24px;
  @apply bg-bg-main;
  outline: none;
}
.search input::placeholder {
  color: #bfbfbf;
}
@media only screen and (max-width: 1050px) {
  .search input[type='text'] {
    max-width: 200px;
  }
}
@media only screen and (max-width: 770px) {
  .search input[type='text'] {
    display: none;
  }
}

.main-container {
  max-width: 1200px;
  margin: 0 auto;
}
.content-container {
  padding: 0 40px 20px 40px;
}

.navigation,
.header,
.main-navigation-header,
.header-home {
  font-weight: 500;
  font-size: 12px;
  position: relative;
}

.navigation {
  overflow: hidden;
}
.navigation.keep {
  height: 60px;
  margin-left: 40px;
  margin-right: 40px;
}

.navigation .icon {
  padding: 0 5px 0px 0;
}

.navigation ul {
  height: 100%;
  list-style: none;
  float: right;
}

.navigation ul .active,
.navigation ul .active a,
.navigation ul .active path,
.navigation ul .active span {
  background: #1b98e0;
  color: #fff;
  fill: #fff;
}

.navigation ul li {
  position: relative;
  float: left;
  height: 100%;
  border-right: 1px solid #ededed;
  padding: 20px 20px 20px 24px;
  cursor: pointer;
}

.navigation ul li label {
  cursor: pointer;
}

.navigation label {
  font-weight: normal;
}

.navigation .breadcumb span {
  letter-spacing: -0.3px;
  color: black;
}

.breadcumb {
  position: fixed;
  z-index: 100;
  top: 20px;
  left: 70px;
  font-size: 13px;
  color: #999;
}

.navigation.keep,
.header {
  border-bottom: 1px solid #ededed;
  background: white;
  border-radius: 5px;
}

.dashboard {
  position: relative;
  background-color: #f7f7f7;
  width: 1146px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  border: none;
}

.small-dash {
  background: #fff;
  width: 422px;
  height: auto;
  min-height: 20px;
  text-align: left;
}

.content {
  position: relative;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 40px 40px 40px;
}

.search .clear {
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  right: 10px;
  top: 10px;
  text-align: center;
  cursor: pointer;
}

.search .clear:after {
  content: 'x';
  display: block;
  color: #fff;
  font-size: 12px;
  font-family: 'gothammedium';
  text-transform: uppercase;
}

.data-title {
  @apply text-sm font-medium text-black-2;
}
.data-value {
  @apply text-sm text-black-1;
}

.ant_popover .ant-popover-inner-content {
  padding: 0;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 56px !important;
  line-height: 56px !important;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.ant-menu-inline {
  /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
  border-right: none !important;
}

.ant-menu-submenu-title .anticon + span {
  margin-left: 4px !important;
}

.nav-title {
  max-width: 280px;
  @apply truncate inline-block;
}
/** 不知道为什么这个样式import的时候没了, 先手动加回来**/
.ant-popover-inner {
  -webkit-box-shadow:
    0 3px 6px -4px rgb(0 0 0 / 12%),
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  box-shadow:
    0 3px 6px -4px rgb(0 0 0 / 12%),
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.line {
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.line1 {
  @apply line;
  -webkit-line-clamp: 1;
}

.line2 {
  @apply line;
  -webkit-line-clamp: 2;
}

.line3 {
  @apply line;
  -webkit-line-clamp: 3;
}
.text-white {
  color: #fff;
}

@layer components {
  .text-h1 {
    @apply text-xl font-semibold text-black-85;
  }
  .text-h2 {
    @apply text-base font-semibold text-black-85;
  }
  .text-h3 {
    @apply text-base text-black-85;
  }

  .text-content {
    @apply text-sm text-black-85;
  }

  .text-sub-content {
    @apply text-xs text-black-85;
  }

  .text-desc {
    @apply text-xs text-black-45;
  }
}

.GridContainer {
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(var(--col-count), 1fr);
  grid-gap: 10px;
  padding: 20px;
}

.drag-item {
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;
}

.bg-white {
  background-color: #fff;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-background-color: #fff !important;
}
